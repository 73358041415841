<template>
    <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <h4>Top 10 Viewed Designers :</h4>

            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <label>Show</label>
                <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                <label>entries</label>

              </div>
            </b-col>
          </b-row>
        </div>

        <b-table ref="viewedTable" class="position-relative text-nowrap" :items="TopViewed" responsive
          :fields="viewedColumns" show-empty empty-text="No matching records found" :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc">

          <template #cell(name)="data">
            <b-link class="font-weight-bold d-block text-nowrap"
            :href="data.item.adminLink">
              {{ data.item.name }}
            </b-link>
          </template>
         
          <template #cell(publicLink)="data">
            <b-link class="font-weight-bold d-block text-nowrap"
            :href="data.item.publicLink">
              {{ data.item.name }}
            </b-link>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">
                Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalTopViewed" :per-page="perPage" first-number
                last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

</template>

<script>
  
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BTable,
    BAvatar, BImg, BFormInput, BInputGroup, BInputGroupAppend, BDropdown,
    BDropdownItem, BPagination,
    BLink,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BButton,
    BBadge,
    BFormSelect
  } from "bootstrap-vue";
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import vSelect from 'vue-select';
  
  export default {
    name: "Dashboard",
    components: {
      BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BAvatar
      , BButton, BTable, BImg, BFormInput, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver,
      BDropdown, BDropdownItem, BFormSelect, BPagination, BLink, BBadge, BFormTextarea, BFormRadio, BFormRadioGroup, BFormGroup, vSelect
    },
    data() {
      return {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [5, 10, 25],
        viewedColumns: [
          { key: 'name', label: 'Name', sortable: true },
          { key: 'userViews', label: 'View Count', sortable: true },
          { key: 'isTopDesigner', label: 'Top Rated',sortable: true },
          { key: 'isHighlyResponsive', label: 'Highly Responsive' ,sortable: true},
          { key: 'publicLink', label: 'Public Link' },
        ],
       
        sortBy: 'count',
        sortDesc: true,

        TopViewed:[],
        totalTopViewed: 0,
        
      };
    },

    computed: {
      dataMeta() {
        return {
          from: this.perPage * (this.currentPage - 1) + (this.TopViewed.length ? 1 : 0),
          to: this.perPage * (this.currentPage - 1) + this.TopViewed.length,
          of: this.totalTopViewed,
        }
      },
      
     
  
      queryParams() {
        return {
          sortBy: this.sortBy,
          sortOrder: this.sortDesc ? 1 : 0,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
        }
      },
      filteredData() {
        if (!this.searchQuery) return this.tableData;
        const search = this.searchQuery.toLowerCase();
        return this.tableData.filter((item) =>
          Object.values(item).some((val) =>
            String(val).toLowerCase().includes(search)
          )
        );
      },
    },
    watch: {
      queryParams: {
        handler() {
          this.fetchViewedDesigners();
         
        },
        immediate: true
      }
    },

    methods: {
    
        async fetchViewedDesigners()
      {
        const params = this.queryParams;
        const response = await this.$http.get('Dashboard/TopViewed', {
          params
        });
        this.TopViewed = response.data.results;
        this.totalTopViewed = response.data.count;

      },
   

  }

}
</script>