
<template>
    <div class="container-fluid py-4">
      <!-- Top Section -->
      <div class="row">
        <div class="col-lg-5">
          <b-card no-body class="card-statistics" style="    height: 390px;">
            <b-card-header>
              <b-card-title>Statuses</b-card-title>
              <b-card-text class="font-small-2 text-muted"></b-card-text>
            </b-card-header>
            <b-card-body>
              <b-row class="text-center">
                <b-col v-for="(status,key) in statuses" :key="key" md="4" sm="6">
                  <div class="status-item">
  
                    <h4 class="font-weight-bolder mt-1">{{ status.item2 }}</h4>
                    <b-card-text class="font-small-2">{{ status.item1 }}</b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-lg-7">
          <!-- Table Container Card -->
          <topRated></topRated>
        </div>
      </div>
  
      <!-- Middle Section -->
      <div class="row mt-2">
        <div class="col-lg-6">
          <Countries></Countries>
        </div>
  
        <div class="col-lg-6">
          <Languages></Languages>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-6">

          <TopViewed></TopViewed>

        </div>
        <div class="col-lg-6">
          <highlyResponsive></highlyResponsive>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-6">
         
        </div>
  
        <div class="col-lg-6">
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-lg-6">
          <ProjectsByClient></ProjectsByClient>
        </div>
        <div class="col-lg-6">
          <ProjectsByDesigner></ProjectsByDesigner>
        </div>
  
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <ProfessionStyles></ProfessionStyles>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BTable,
    BAvatar, BImg, BFormInput, BInputGroup, BInputGroupAppend, BDropdown,
    BDropdownItem, BPagination,
    BLink,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BButton,
    BBadge,
    BFormSelect
  } from "bootstrap-vue";
  import vSelect from 'vue-select';
  import topRated from './TopRated.vue'
 import TopViewed from './TopViewed.vue'
import Languages from "./Languages.vue";
import Countries from "./Countries.vue";
import highlyResponsive from "./HighlyResponsive.vue";
import ProjectsByClient from "./ProjectsByClient.vue";
import ProjectsByDesigner from "./ProjectsByDesigner.vue";
import ProfessionStyles from "./ProfessionStyles.vue";

  export default {
    name: "Dashboard",
    components: {
      BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BAvatar
      , BButton, BTable, BImg, BFormInput, BInputGroup, BInputGroupAppend,
      BDropdown, BDropdownItem, BFormSelect, BPagination, BLink, BBadge, BFormTextarea, BFormRadio, BFormRadioGroup, BFormGroup, vSelect
      ,topRated,TopViewed,Languages,Countries,highlyResponsive,ProjectsByClient,ProjectsByDesigner,ProfessionStyles
    },
    data() {
      return {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [5, 10, 25],
        nameQuery: '',
        emailQuery: '',
        typeQuery: '',
        tableColumns: [
          { key: 'name', label: 'Name', sortable: true },
          { key: 'subject', label: 'Subject', sortable: true },
          { key: 'emailFrom', label: 'From' },
  
        ],
        viewedColumns: [
          { key: 'name', label: 'Name', sortable: true },
          { key: 'userViews', label: 'View Count', sortable: true },
          { key: 'isTopDesigner', label: 'Top Rated',sortable: true },
          { key: 'isHighlyResponsive', label: 'Highly Responsive' ,sortable: true},
          { key: 'adminLink', label: 'Admin Link' },
          { key: 'publicLink', label: 'Public Link' },
        ],
        countriesColumns: [
          { key: 'country', label: 'Country', sortable: true },
          { key: 'count', label: 'Designer Count', sortable: true },
      
        ],
        languagesColumns: [
          { key: 'language', label: 'Language', sortable: true },
          { key: 'count', label: 'Designer Count', sortable: true },
      
        ],
        topRatedColumns: [
          { key: 'name', label: 'Designer Name', sortable: true },
          { key: 'adminLink', label: 'Admin Link', sortable: false },
          { key: 'publicLink', label: 'Public Link', sortable: false },
      
        ],
        viewedSortBy: 'userViews',
        viewedSortDesc: false,
        sortBy: 'name',
        sortDesc: false,
        templates: [],
        totalTemplates: 0,
        template: {},
        languageSortBy: 'language',
        languageSortDesc: false,
       
        TopViewed:[],
        totalTopViewed: 0,
        Countries:[],
        totalCountries: 0,
        Languages:[],
        totallangauges: 0,
        TopDesigner:[],
        totalTopDesigner: 0,
        statuses: [],
      };
    },
  
    computed: {
     
      
    },
    watch: {
      queryParams: {
        handler() {
          this.fetchStatuses();
         
        },
        immediate: true
      }
    },
  
    methods: {
      async fetchStatuses()
      {
        const response = await this.$http.get('Dashboard', {
          
        });
        this.statuses = response.data;
     

      },
    }
  };
  
  </script>
  