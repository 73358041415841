<template>
    <b-card no-body class="mb-0">
           <div class="m-2">
             <!-- Table Top -->
             <b-row>
               <!-- Per Page -->
               <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                 <h6>Projects by Designers:</h6>
 
               </b-col>
               <!-- Search -->
               <b-col cols="12" md="6">
                 <div class="d-flex align-items-center justify-content-end">
                   <label>Show</label>
                   <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                     :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                   <label>entries</label>
 
                 </div>
               </b-col>
             </b-row>
           </div>
 
           <b-table ref="templatesTable" class="position-relative text-nowrap" :items="DesignerProjects" responsive
             :fields="DesignerProjectsColumns" show-empty empty-text="No matching records found" :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc">
 
             <template #cell(name)="data">
               <b-link class="font-weight-bold d-block text-nowrap"
               :href="data.item.adminLink">
                 {{ data.item.name }}
               </b-link>
             </template>
         

             <template #cell(publicLink)="data">
               <b-link class="font-weight-bold d-block text-nowrap"
               :href="data.item.publicLink">
                 {{ data.item.publicLink }}
               </b-link>
             </template>
 
 
           </b-table>
 
           <div class="mx-2 mb-0">
             <b-row>
               <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                 <span class="text-muted">
                   Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                 </span>
               </b-col>
               <!-- Pagination -->
               <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                 <b-pagination v-model="currentPage" :total-rows="totalDesignerProjects" :per-page="perPage" first-number
                   last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                   <template #prev-text>
                     <feather-icon icon="ChevronLeftIcon" size="18" />
                   </template>
 
                   <template #next-text>
                     <feather-icon icon="ChevronRightIcon" size="18" />
                   </template>
                 </b-pagination>
               </b-col>
             </b-row>
           </div>
         </b-card>

</template>

<script>
 
 import {
   BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BTable,
   BAvatar, BImg, BFormInput, BInputGroup, BInputGroupAppend, BDropdown,
   BDropdownItem, BPagination,
   BLink,
   BFormGroup,
   BFormRadioGroup,
   BFormRadio,
   BFormTextarea,
   BButton,
   BBadge,
   BFormSelect
 } from "bootstrap-vue";
 import { ValidationObserver, ValidationProvider } from 'vee-validate'
 import vSelect from 'vue-select';
 
 export default {
   name: "Dashboard",
   components: {
     BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BAvatar
     , BButton, BTable, BImg, BFormInput, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver,
     BDropdown, BDropdownItem, BFormSelect, BPagination, BLink, BBadge, BFormTextarea, BFormRadio, BFormRadioGroup, BFormGroup, vSelect
   },
   data() {
     return {
       currentPage: 1,
       perPage: 5,
       perPageOptions: [5, 10, 25],
       DesignerProjectsColumns: [
         { key: 'name', label: 'Designer Name', sortable: true },
         { key: 'total', label: 'Total Projects', sortable: true },
         { key: 'amountSum', label: 'Total Amount', sortable: true },
         { key: 'publicLink', label: 'Public Link', sortable: false },
     
       ],
      
       sortBy: 'name',
       sortDesc: false,

       DesignerProjects:[],
       totalDesignerProjects: 0,
       
     };
   },

   computed: {
     dataMeta() {
       return {
         from: this.perPage * (this.currentPage - 1) + (this.DesignerProjects.length ? 1 : 0),
         to: this.perPage * (this.currentPage - 1) + this.DesignerProjects.length,
         of: this.totalDesignerProjects,
       }
     },
     
    
 
     queryParams() {
       return {
         sortBy: this.sortBy,
         sortOrder: this.sortDesc ? 1 : 0,
         skip: (this.currentPage - 1) * this.perPage,
         take: this.perPage,
       }
     },
     filteredData() {
       if (!this.searchQuery) return this.tableData;
       const search = this.searchQuery.toLowerCase();
       return this.tableData.filter((item) =>
         Object.values(item).some((val) =>
           String(val).toLowerCase().includes(search)
         )
       );
     },
   },
   watch: {
     queryParams: {
       handler() {
         this.fetchData();
        
       },
       immediate: true
     }
   },

   methods: {
   
   async fetchData()
   {
     const params = this.queryParams;
     const response = await this.$http.get('Dashboard/DesignerProjects', {
       params
     });
     this.DesignerProjects = response.data.results;
     this.totalDesignerProjects = response.data.count;

   },
  

 }

}
</script>