<template>
    <b-card no-body class="mb-0">
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <h6>Languages Spoken by Designers:</h6>
  
                </b-col>
                <!-- Search -->
                <b-col cols="12" md="6">
                  <div class="d-flex align-items-center justify-content-end">
                    <label>Show</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>entries</label>
  
                  </div>
                </b-col>
              </b-row>
            </div>
  
            <b-table ref="templatesTable" class="position-relative text-nowrap" :items="LanguagesWithTotal" responsive
              :fields="languagesColumns" show-empty empty-text="No matching records found" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc">
  
              
            </b-table>
  
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totallangauges" :per-page="perPage" first-number
                    last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
  
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card>

</template>

<script>
  
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BTable,
    BAvatar, BImg, BFormInput, BInputGroup, BInputGroupAppend, BDropdown,
    BDropdownItem, BPagination,
    BLink,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BButton,
    BBadge,
    BFormSelect
  } from "bootstrap-vue";
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import vSelect from 'vue-select';
  
  export default {
    name: "Dashboard",
    components: {
      BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BAvatar
      , BButton, BTable, BImg, BFormInput, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver,
      BDropdown, BDropdownItem, BFormSelect, BPagination, BLink, BBadge, BFormTextarea, BFormRadio, BFormRadioGroup, BFormGroup, vSelect
    },
    data() {
      return {
        currentPage: 1,
        perPage: 5,
        perPageOptions: [5, 10, 25],
        languagesColumns: [
          { key: 'language', label: 'Language', sortable: true },
          { key: 'count', label: 'Designer Count', sortable: true },
      
        ],
       
        sortBy: 'Count',
        sortDesc: true,

        Languages:[],
        totallangauges: 0,
        
      };
    },

    computed: {
        totalDesignerCount() {
    return this.Languages.reduce((total, language) => {
      return language.totalCount;
    }, 0);
  },

  LanguagesWithTotal() {
  const LanguagesWithTotal = this.Languages.concat([{
    language: 'Total',  // Label for the total row
    count: this.totalDesignerCount,  // The actual total count
  }]);
 
  return LanguagesWithTotal;
},

      dataMeta() {
        return {
          from: this.perPage * (this.currentPage - 1) + (this.Languages.length ? 1 : 0),
          to: this.perPage * (this.currentPage - 1) + this.Languages.length,
          of: this.totallangauges,
        }
      },
      
     
  
      queryParams() {
        return {
          sortBy: this.sortBy,
          sortOrder: this.sortDesc ? 1 : 0,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
        }
      },
      filteredData() {
        if (!this.searchQuery) return this.tableData;
        const search = this.searchQuery.toLowerCase();
        return this.tableData.filter((item) =>
          Object.values(item).some((val) =>
            String(val).toLowerCase().includes(search)
          )
        );
      },
    },
    watch: {
      queryParams: {
        handler() {
          this.fetchLanguages();
         
        },
        immediate: true
      }
    },

    methods: {
    
        async fetchLanguages()
      {
        const params = this.queryParams;
        const response = await this.$http.get('Dashboard/Languages', {
          params
        });
        this.Languages = response.data.results;
        this.totallangauges = response.data.count;

      },
   

  }

}
</script>